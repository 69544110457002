.button {
  background-color: $blitz-blue;
  color: white;
  padding: 7px 10px;
  border: 1px solid $blitz-blue;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 15pt;
  text-align: center;
  font-size: 11pt;
  min-width: 170px;

  &:hover {
    color: $blitz-blue;
    background-color: transparent;
    cursor: pointer;
  }

  &--full-width {
    width: 100%;
  }

  &--reverse {
    background-color: transparent;
    color: $blitz-blue;
    border: 1px solid $blitz-blue;

    &:hover {
      color: white;
      background-color: $blitz-blue;
    }
  }

  &:disabled, &--disabled{
    background-color: $blitz-dark-grey;
    border-color: $blitz-dark-grey;
    color: grey;

    &:hover {
      color: grey;
      background-color: $blitz-dark-grey;
      border-color: $blitz-dark-grey;
      cursor: not-allowed;
    }
  }

  &--danger {
    background-color: $blitz-red;
    border-color: $blitz-red;
    color: white;

    &:hover {
      color: $blitz-red;
      background-color: transparent;
    }
  }
}
