.input {
  background-color: white;
  border: 1px solid $blitz-grey;
  padding: 5px;
  color: $blitz-black;

  &--full {
    width: 100%;
  }
}
