@import "variables";

.blitz-popover {
  @media #{$mobile-max-width} {
    width: 100% !important;
    left: 0;
    margin-top: 5px;
    z-index: 2;
  }
  font-size: 18px;
  margin-top: 20px;
  display: none;
  position: absolute;
  width: 460px;
  background-color: $blitz-white;
  transition: all $animation-time $transition-type;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  padding: 40px 49px;

  &__section {
    margin-bottom: 35px;

    &__add-new-button {
      text-align: left;
      font-size: 18px;
      font-family: $regular;
      color: #3F90FF;
      padding: 13px 19px;
      cursor: pointer;
    }
  }

  &__labels {
    margin-bottom: 17px;
  }
  &__input {
    width: 100%;
    height: 45px;
    padding: 0 20px;
    @include placeholder-style(rgba(66, 66, 66, .5));
  }
  &__close {
    transition: all $animation-time $transition-type;
    position: absolute;
    background: transparent;
    color: #adadad;
    border: 0;
    font-size: 27px;
    line-height: 20px;
    top: 22px;
    right: 21px;
    padding: 0;
    cursor: pointer;
    &:hover {
      filter: invert(0.15)
    }
  }
  &.visible {
    display: block;
  }
  &__export {
    @media #{$mobile-max-width} {
      width: 100%;
    }
    width: 250px;
    padding: 68px 49px 18px 49px;
    .images {
      height: 67.5px;
      margin-bottom: 15px;
      .column-1 {
        float: left;
        margin-right: 17px;
        img {
          width: 51.5px;
          height: 67.5px;
        }
      }
      .column-2 {
        padding-top: 25px;
        text-align: left;
        float: left;
      }
    }
  }
}
