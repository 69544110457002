// styles in src/style directory are applied to the whole page
@import 'variables';
@import "../../../node_modules/@angular-mdl/core/scss/mdl/radio/radio";
@import "../../../node_modules/@angular-mdl/core/scss/mdl/shadow/shadow";
@import "../../../node_modules/@angular-mdl/core/scss/mdl/slider/slider";
@import 'mdl-select';
@import 'mdl-checkbox';
@import 'mdl-chip';
@import 'blitz-popover';
@import 'form/input';
@import 'form/select';
@import 'button';

// Fonts
@font-face {
    font-family: 'pt-sans-bold';
    src: url('../fonts/PTS75F-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt-sans-regular';
    src: url('../fonts/PTS55F-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt-sans-italic';
    src: url('../fonts/PTS56F-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Regular.eot');
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('../fonts/MaterialIcons-Regular.woff2') format('woff2'),
       url('../fonts/MaterialIcons-Regular.woff') format('woff'),
       url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
}

//Fix with the focus on the fields: email/password in the login component, the cursor moves down from the input field
// ToDo Need find another solution
html, body{
  //-webkit-overflow-scrolling : touch !important;
  //overflow: auto !important;
  //height: 100% !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 28px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

//Common elements

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

body {
  margin: 0;
  font-family: $regular;
  color: $blitz-dark-grey;
  background-color: $blitz-white;

  &.no-scroll {
    overflow-y: hidden;
  }
}

header {
  @media print {
    display: none;
  }
  position: fixed;
  width: 100%;
  z-index: 4;

  blitz-header {
    &.scrolled {
      #first-header {
        &.black-background {
          padding: 10px 40px;

          @media #{$header-mobile-long} {
            padding: 22px 40px;
          }

          @media #{$header-mobile-medium} {
            padding: 4px 40px;
          }
        }

        nav {
          .search-bar#search_bar {
            width: 225px;
            margin-right: 40px;
            transform: scale(1);
            transition: transform $animation-time $transition-type,
                        margin $animation-time $transition-type,
                        width $animation-time $transition-type;
          }
        }
      }

      // Todo: I'm not deleted this, because customer can change his mind about 'hiding second header when scrolling down'
      //blitz-second-header {
        //.main-wrapper.search-bar.search-bar-header {
        //  height: 0;
        //  padding-top: 0;
        //  padding-bottom: 0;
        //
        //  .desktop {
        //    .search-input-wrapper {
        //      height: 0;
        //
        //      input {
        //        transition: all $animation-time $transition-type;
        //        height: 0;
        //        border-width: 0;
        //      }
        //    }
        //    .information-popup-wrapper {
        //      height: 0;
        //      border-width: 0;
        //    }
        //
        //    i.fa.fa-search, i.fa.fa-info {
        //      transition: all $animation-time $transition-type;
        //      display: none;
        //    }
        //  }
        //  .mobile {
        //    display: none;
        //  }
        //}
      //}
    }

    &.is-member {
      + blitz-second-header {

        @media #{$header-mobile-long} {
          .main-wrapper.search-bar.search-bar-header {
            height: 46px;
          }
        }
      }
    }
  }
}

label {
  width: 100%;
  padding-bottom: 12px;
}

.text-input, .mdl-textfield__input {
  background: transparent;
  border-radius: 2px;
  border: 1px solid $blitz-light-grey;
  height: 43px;
  padding: 0 10px;
  color: $blitz-dark-grey;
  font-size: 18px;
  transition: box-shadow $animation-time, border $animation-time;
  font-family: $regular;

  &.ng-invalid.ng-touched {
    border-color: $blitz-red;
  }
}

.hidden {
  display: none;
}

.text-input {
  &:focus {
    box-shadow: 0 0 5px 1px $blitz-blue;
  }

  &::placeholder {
    color: $blitz-dark-grey;
  }
}

textarea.text-input {
  width: 100%;
  height: 100px;
  min-height: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  resize: vertical;
}

.alert {
  &-danger {
    color: $blitz-red;
  }
}

.explanation {
  text-align: center;
  color: #067D62;
  padding: 13px 33px;
  border: 1px solid #067D62;
}

ul, ol {
  margin: 0;
}

.runway {
  width: 100%;

  &-wrapper {
    max-width: 1128px;
    padding-right: 20px;
    padding-left: 20px;
    margin: auto;
  }
}

.default-link-wrapper {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 55px;
  }

  .default-link {
    text-decoration: none;
    transition: color 0.25s ease-in-out;
    outline: none;

    &:hover {
      text-decoration: underline;
      color: $blitz-black;
    }
  }
}

.section-title {
  font-family: $bold;
  font-size: 35px;
  color: $blitz-black;
}

.with-bold {
  font-family: $bold;
}

.text-wrapper {
  .text-title {
    font-family: $bold;
    color: $blitz-black;
    font-size: 25px;
  }

  .plain-text {
    margin-top: 30px;
    color: $blitz-dark-grey;
    font-size: 18px;
    line-height: 28px;

    .paragraph {
      padding-top: 30px;
    }
  }
}

.common-btn {
  color: $blitz-white;
  font-family: $regular;
  padding: 8px 26px;
  background-color: transparent;
  border: 1px solid $blitz-white;
  transition: all $animation-time $transition-type;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.35;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.blue, &.grey, &.red {
    font-size: 19px;
    min-width: 164px;
    padding: 11px 26px;
  }

  &.blue {
    color: $blitz-blue;
    border-color: $blitz-blue;

    @media #{$mobile-max-width} {
      min-width: 33%;
    }

    &:hover {
      background-color: $blitz-blue;
      color: $blitz-white;
    }

    &.disabled {
      &:hover {
        background-color: $blitz-white;
        color: $blitz-blue;
      }
    }
  }

  &.red {
    color: $blitz-red;
    border-color: $blitz-red;

    @media #{$mobile-max-width} {
      min-width: 33%;
    }

    &:hover {
      background-color: $blitz-red;
      color: $blitz-white;
    }

    &.disabled {
      &:hover {
        background-color: $blitz-white;
        color: $blitz-red;
      }
    }
  }

  &.grey {
    color: $blitz-grey;
    border-color: $blitz-grey;

    &:hover {
      background-color: $blitz-grey;
      color: $blitz-white;
    }

    &.disabled {
      &:hover {
        background-color: transparent;
        color: $blitz-grey;
      }
    }
  }

  &.light-blue {
    background-color: $blitz-light-blue;
    color: $blitz-dark-grey;
    font-size: 18px;
    padding: 10px 0;

    &.active, &:hover, &:focus {
      background-color: $blitz-blue;
      border-color: $blitz-blue;
      color: $blitz-white;
    }
  }

  &.big {
    font-size: 22px;
    padding: 15px 0;
    width: 100%;
    max-width: 300px;

    &:hover {
      background-color: $blitz-white;
      color: $blitz-black;
    }
  }
}

.custom-snackbar {
  background: #3f90ff;
  color: #ffffff;
  border-radius: 0;

  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button {
    color: grey;
    background: #fff;
  }
}
