@import 'variables';

.mdl-checkbox {
  position: relative;

  z-index: 1;

  vertical-align: middle;

  display: inline-block;

  box-sizing: border-box;
  // height: $checkbox-label-height;
  margin: 0;
  padding: 0;

  &.is-upgraded {
    padding-left: $checkbox-button-size + $checkbox-padding;
  }
}

.mdl-checkbox__input {
  line-height: $checkbox-label-height;

  .mdl-checkbox.is-upgraded & {
    // Hide input element, while still making it respond to focus.
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
  }
}

.mdl-checkbox__box-outline {
  position: absolute;
  top: $checkbox-top-offset;
  left: 0;

  display: inline-block;

  box-sizing: border-box;
  width: $checkbox-button-size;
  height: $checkbox-button-size;
  margin: 0;

  cursor: pointer;
  overflow: hidden;

  border: 1px solid $checkbox-off-color;
  border-radius: 2px;

  z-index: 2;

  .mdl-checkbox.is-checked & {
    border: 1px solid $checkbox-off-color;
  }

  fieldset[disabled] .mdl-checkbox &,
  .mdl-checkbox.is-disabled & {
    border: 1px solid $checkbox-disabled-color;
    cursor: auto;
  }
}

.mdl-checkbox__focus-helper {
  position: absolute;
  top: $checkbox-top-offset;
  left: 0;

  display: inline-block;

  box-sizing: border-box;
  width: $checkbox-button-size;
  height: $checkbox-button-size;
  border-radius: 2px;

  background-color: transparent;

  .mdl-checkbox.is-focused & {
    box-shadow: 0 0 0 ($checkbox-button-size / 2) rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mdl-checkbox.is-focused.is-checked & {
    box-shadow: 0 0 0 ($checkbox-button-size / 2) $checkbox-focus-color;
    background-color: $checkbox-focus-color;
  }
}

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 9px;
  width: 12px;

  background: transparent;

  transition-duration: $animation-time;
  transition-timing-function: $transition-type;
  transition-property: all;

  .mdl-checkbox.is-checked & {
    background: transparent url($checkbox-tick-url);
  }

  fieldset[disabled] .mdl-checkbox.is-checked &,
  .mdl-checkbox.is-checked.is-disabled & {
    background: transparent url($checkbox-tick-url);
  }
}

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: $checkbox-label-font-size;
  line-height: $checkbox-label-height;
  margin: 0;

  fieldset[disabled] .mdl-checkbox &,
  .mdl-checkbox.is-disabled & {
    color: $checkbox-disabled-color;
    cursor: auto;
  }
}

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -(($checkbox-ripple-size - $checkbox-label-height) / 2);
  left: -(($checkbox-ripple-size - $checkbox-button-size) / 2);

  box-sizing: border-box;
  width: $checkbox-ripple-size;
  height: $checkbox-ripple-size;
  border-radius: 50%;

  cursor: pointer;

  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);

  & .mdl-ripple {
    background: $ripple-bg-color;
  }

  fieldset[disabled] .mdl-checkbox &,
  .mdl-checkbox.is-disabled & {
    cursor: auto;
  }

  fieldset[disabled] .mdl-checkbox & .mdl-ripple,
  .mdl-checkbox.is-disabled & .mdl-ripple {
    background: transparent;
  }
}

.mdl-ripple {
  background       : $ripple-bg-color;
  border-radius    : 50%;
  height           : 50px;
  left             : 0;
  opacity          : 0;
  pointer-events   : none;
  position         : absolute;
  top              : 0;
  transform        : translate(-50%, -50%);
  width            : 50px;
  overflow         : hidden;

  &.is-animating {
    transition: all .5s cubic-bezier(0, 0, 0.2, 1);
  }

  &.is-visible {
    opacity: 0.3;
  }
}
