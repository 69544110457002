@import 'variables';

mdl-select {
  height: 45px;

  .mdl-textfield {
    padding: 0;
    width: 100%;
    position: relative;
    font-size: 18px;
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;

    input {
      outline: none;
      width: 100%;
      border: 1px solid $blitz-light-grey;
      border-radius: 2px;
      padding: 0 40px 0 20px;
      height: 43px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    label.mdl-textfield__label {
      top: 11px;
      padding-left: 20px;
      padding-right: 31px;
      padding-bottom: 0;
      bottom: 0;
      color: rgba(0,0,0, 0.26);
      font-size: 18px;
      left: 0;
      right: 0;
      pointer-events: none;
      position: absolute;
      display: block;
      width: calc(100% - 10px);
      overflow: hidden;
      white-space: nowrap;
      text-align: left;

      &::after {
        visibility: hidden!important;
        bottom: 20px;
        content: '';
        height: 2px;
        left: 45%;
        position: absolute;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        width: 10px;
      }
    }

    .mdl-textfield__error {
      color: rgb(213,0,0);
      position: absolute;
      font-size: 12px;
      margin-top: 3px;
      visibility: hidden;
      display: block;
    }

    .mdl-popover {
      background: #fff;
      border: none;
      display: none;
      margin: 0;
      overflow: visible;
      padding: 0;
      position: absolute;

      &.is-visible,
      &.is-animating {
        display: block;
        z-index: 3;
      }

      &.is-visible {
        display: block;
        z-index: 3;
      }

      .mdl-list {
        max-height: 280px;
        overflow-y: auto;
        padding: 0;
        display: block;
        list-style: none;
        border: 1px solid $blitz-light-grey;
        border-radius: 2px;

        .direction-up {
          bottom: 45px!important;
        }

        .mdl-option__container {
          .mdl-list__item {
            cursor: pointer;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.04em;
            display: -ms-flexbox;
            display: flex;
            min-height: 48px;
            box-sizing: border-box;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -ms-flex-align: center;
            align-items: center;
            padding: 16px 20px;
            color: $blitz-dark-grey;
            overflow: hidden;
            transition: background-color $animation-time $transition-type;

            &:hover {
              background-color: rgba(0,0,0, 0.15);
            }
            .mdl-list__item-primary-content {
              -ms-flex-order: 0;
              order: 0;
              -ms-flex-positive: 2;
              flex-grow: 2;
              text-decoration: none;
              box-sizing: border-box;
              display: -ms-flexbox;
              -ms-flex-align: center;
              align-items: center;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
            }
          }
        }
      }
    }

    .material-icons {
      margin-top: 10px;
      padding-right: 15px;
      color: $blitz-grey;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.is-dirty {
      label.mdl-textfield__label {
        visibility: hidden;
      }
    }

    &.is-disabled {
      background-color: #f4f4f4;

      label.mdl-textfield__label {
        color: #9a9a9a;
      }
    }
  }

  &.ng-touched {
    &.ng-invalid {
      input {
        border-color: $blitz-red;
      }
    }
  }
}
