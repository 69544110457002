@import "../../../node_modules/susy/sass/susy";
@import "../../../node_modules/susy/sass/plugins/svg-grid";

//Variables

$blitz-blue: #3f90ff;
$blitz-deep-blue: #3fb0ff;
$blitz-light-blue: #ebf6ff;
$blitz-black: #141414;
$blitz-dark-grey: #424242;
$blitz-grey: #969696;
$blitz-light-grey: #bbb2b2;
$blitz-white: #ffffff;
$blitz-red: #cf3d3d;
$blitz-border-grey: #e2e2e2;
$blitz-star: #ee8b12;

$regular: pt-sans-regular;
$bold: pt-sans-bold;
$italic: pt-sans-italic;

$mobile-max-width: "(max-width: 767px)";
$max-grid-width: "(max-width: 1128px)";

$header-mobile-long: "(max-width: 950px)";
$header-mobile-medium: "(max-width: 670px)";
$header-mobile-short: "(max-width: 450px)";

$animation-time: .3s;
$transition-type: cubic-bezier(0.215, 0.610, 0.355, 1.000);

$susy: ( columns: susy-repeat(12), gutters: 18px, );

$radio-color: $blitz-blue;
$radio-off-color: $blitz-light-grey;
$radio-disabled-color: $blitz-light-grey;

$range-color: $blitz-blue;

$checkbox-label-height: 28px;
$checkbox-label-font-size: 18px;
$checkbox-button-size: 22px;
$checkbox-padding: 11px;
$checkbox-top-offset: 3px;
$checkbox-off-color: $blitz-grey;
$checkbox-color: transparent;
$checkbox-disabled-color: #c1c1c1;
$checkbox-focus-color: $blitz-grey;
$checkbox-image-path: img;
$checkbox-ripple-size: 42px;
$checkbox-tick-url: "../../assets/img/checkbox-tick.png";
$ripple-bg-color: $blitz-dark-grey;

@mixin icon-flip($horiz, $vert, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation}, mirror=1)";
  -webkit-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}

@mixin placeholder-style($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
  &:placeholder-shown {
    color: $color;
  }
}
