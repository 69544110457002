@import 'variables';

$chip-height: 32px;
$text-color-primary: $blitz-black;
$chip-font-size: 14px;

/* SHADOWS */

$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

@mixin shadow-2dp() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0 3px 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0 1px 5px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}

.mdl-chip {
  height: $chip-height;
  font-family: $regular;
  line-height: $chip-height;
  padding: 0 10px 0 15px;
  border: 0;
  border-radius: $chip-height / 2;
  background-color: #f4f4f4;
  display: inline-block;
  color: $text-color-primary;
  margin: 0 10px 10px 0;
  font-size: 0;
  white-space: nowrap;

  &__text {
    font-size: $chip-font-size;
    vertical-align: middle;
    display: inline-block;
    text-transform: uppercase;
  }

  &__action {
    height: 24px;
    width: 24px;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    margin: 0 0 0 4px;
    font-size: $chip-font-size;
    text-decoration: none;
    color: #db0033;
    border: none;
    outline: none;
    overflow: hidden;
    transition: all $animation-time $transition-type;

    &:hover {
      filter: invert(.15)
    }
  }

  &__contact {
    height: $chip-height;
    width: $chip-height;
    border-radius: $chip-height / 2;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    overflow: hidden;
    text-align: center;
    font-size: 18px;
    line-height: 32px;
  }

  &:focus {
    outline: 0;
    @include shadow-2dp();
  }

  &:active {
    background-color: #f4f4f4;
  }

  &--deletable {
    padding-right: 4px;
  }

  &--contact {
    padding-left: 0;
  }
}
